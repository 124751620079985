import { useState } from "react"
import { useQuestions } from "../../contexts/question-provider"
import { AddQuestion } from "./AddQuestion"
import { Collapsible } from "../Collapsible"
import { Question } from "./Question"

export const QuestionsSection = () => {
  const [collapsed, setCollapsed] = useState(true)
  const { isLoading, questions, loadNext } = useQuestions()

  if (isLoading) {
    return null
  }

  return (
    <div className="section" id="questions">
      <h1>Questions</h1>
      <p>
        Manage the questions that will appear in the WildlifeNL mobile application when users make a submission of an
        interact.
      </p>

      <Collapsible collapse={collapsed}>
        <div className="subsections">
          <div className="subsection">
            <h4>Modify a question</h4>

            <div className="item-list questions">
              {questions?.length ? (
                questions?.map((question) => <Question key={question.id} question={question} />)
              ) : (
                <div style={{ textAlign: "center", fontSize: 13 }}>No questions</div>
              )}
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: 16 }}>
              <input type="button" style={{ cursor: "pointer" }} value="Load next" onClick={loadNext} />
            </div>
          </div>

          <div className="subsection">
            <h4>Add a question</h4>

            <AddQuestion />
          </div>
        </div>
      </Collapsible>

      <input
        type="button"
        className="toggle-btn"
        style={{ padding: "12px 0" }}
        value={collapsed ? "↑ Hide section" : "↓ Expand section"}
        onClick={() => setCollapsed((prev) => !prev)}
      />
    </div>
  )
}
