import { ChangeEvent, useRef, useState } from "react"
import { useQuestions } from "../../contexts/question-provider"
import { Collapsible } from "../Collapsible"

interface QuestionProps {
  question: Question
}

export const Question = ({ question }: QuestionProps) => {
  const [question_, setQuestion] = useState(question)
  const { deleteQuestion, updateQuestion, interactionTypes, userTypes, questionTypes } = useQuestions()
  const optionRef = useRef<HTMLInputElement>(null)
  const [collapse, setCollapse] = useState(false)

  const needsPlaceholder = ["text", "dropdown", "multiselect"].includes(question_.questionType.id)
  const needsOptions = ["radio_button", "dropdown", "multiselect"].includes(question_.questionType.id)

  const handleAddOption = () => {
    const option = optionRef.current?.value

    if (option && !question_.specifications.includes(option)) {
      const specifications = [...question_.specifications, option]
      setQuestion((prev) => ({
        ...prev,
        specifications,
      }))
      optionRef.current.value = ""
    }
  }

  const handleRemoveOption = (option: string) => {
    const specifications = question_.specifications.filter((s) => s !== option)
    setQuestion((prev) => ({
      ...prev,
      specifications,
    }))
  }

  const handleQuestionTypeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const label = event.target.options[event.target.selectedIndex].label
    const questionType = questionTypes?.filter((t) => t.label === label)[0]!
    setQuestion((prev) => ({ ...prev, questionType }))
  }

  const handleOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const questionOrder = event.target.value as unknown as number
    setQuestion((prev) => ({ ...prev, questionOrder }))
  }

  const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const question = event.target.value
    setQuestion((prev) => ({ ...prev, question }))
  }

  const handlePlaceholderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const placeholder = event.target.value
    setQuestion((prev) => ({ ...prev, placeholder }))
  }

  const handleIsActiveChange = async (isActive: boolean) => {
    setQuestion((prev) => ({ ...prev, isActive }))
    question_.isActive = isActive
    await updateQuestion!(question_)
  }

  const handleIsOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isOptional = event.target.checked
    setQuestion((prev) => ({ ...prev, isOptional }))
  }

  const handleAddInteractionType = (interactionType: InteractionType) => {
    const interactionTypes = [...question_.interactionTypes, interactionType]
    setQuestion((prev) => ({ ...prev, interactionTypes }))
  }

  const handleAddUserType = (userType: UserType) => {
    const userTypes = [...question_.userTypes, userType]
    setQuestion((prev) => ({ ...prev, userTypes }))
  }

  const handleRemoveInteractionType = (interactionType: InteractionType) => {
    const interactionTypes = question_.interactionTypes.filter((t) => t.id !== interactionType.id)
    setQuestion((prev) => ({ ...prev, interactionTypes }))
  }

  const handleRemoveUserType = (userType: UserType) => {
    const userTypes = question_.userTypes.filter((t) => t.id !== userType.id)
    setQuestion((prev) => ({ ...prev, userTypes }))
  }

  return (
    <div className="list-item">
      <div className="header">
        <div>{question.question}</div>
        <input
          type="button"
          className="toggle-btn"
          value={collapse ? "↑ Hide section" : "↓ Expand section"}
          onClick={() => setCollapse((prev) => !prev)}
        />
      </div>

      <Collapsible collapse={collapse}>
        <div className="content">
          <div className="item">
            <div>Question</div>
            <input
              type="text"
              defaultValue={question.question}
              onChange={handleQuestionChange}
              style={{ width: 300 }}
            />
          </div>

          <div className="item">
            <div>Order</div>
            <input type="number" defaultValue={question.questionOrder} onChange={handleOrderChange} />
          </div>

          <div className="item">
            <div>Type</div>
            <select defaultValue={question.questionType.label} onChange={handleQuestionTypeSelect}>
              {questionTypes?.map((t) => (
                <option key={t.id} value={t.label}>
                  {t.label}
                </option>
              ))}
            </select>
          </div>

          {needsPlaceholder && (
            <div className="item">
              <div>Placeholder</div>
              <input
                type="text"
                placeholder="Enter hint text"
                defaultValue={question_.placeholder}
                onChange={handlePlaceholderChange}
                style={{ width: 300 }}
              />
            </div>
          )}

          {needsOptions && (
            <div className="item">
              <div>Options</div>
              <div className="options">
                <div className="options-list">
                  {question_.specifications.map((option) => (
                    <div className="option" key={option}>
                      <input type="button" value="Remove" onClick={() => handleRemoveOption(option)} />
                      {option}
                    </div>
                  ))}
                </div>

                <div className="input-tray">
                  <input type="button" value="Add" onClick={handleAddOption} />
                  <input type="text" placeholder="Option 1" ref={optionRef} />
                </div>
              </div>
            </div>
          )}

          <div className="item">
            <div>Is optional</div>
            <input type="checkbox" defaultChecked={question.isOptional} onChange={handleIsOptionalChange} />
          </div>

          <div className="item">
            <div>Interaction types</div>
            <div className="radio-buttons">
              {interactionTypes?.map((x) => (
                <label key={x.id} className="radio-button-item">
                  {x.label}
                  <input
                    type="checkbox"
                    value={x.id}
                    defaultChecked={!!question_.interactionTypes.filter((y) => y.id === x.id)[0]}
                    onChange={(e) => (e.target.checked ? handleAddInteractionType(x) : handleRemoveInteractionType(x))}
                  />
                </label>
              ))}
            </div>
          </div>

          <div className="item">
            <div>Target users</div>
            <div className="radio-buttons">
              {userTypes?.map((x) => (
                <label key={x.id} className="radio-button-item">
                  {x.label}
                  <input
                    type="checkbox"
                    value={x.id}
                    defaultChecked={!!question_.userTypes.filter((y) => y.id === x.id)[0]}
                    onChange={(e) => (e.target.checked ? handleAddUserType(x) : handleRemoveUserType(x))}
                  />
                </label>
              ))}
            </div>
          </div>

          <div className="item">
            <div style={{ opacity: 0.5 }}>Creation date</div>
            <div style={{ opacity: 0.5 }}>
              {new Date(question?.creationDate!).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </div>
          </div>

          <div className="item">
            <div style={{ opacity: 0.5 }}>Modified date</div>
            <div style={{ opacity: 0.5 }}>
              {new Date(question?.lastModified!).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </div>
          </div>

          <div className="btns" style={{ margin: "16px 0" }}>
            <div className="btns">
              <input
                type="button"
                value="Save"
                onClick={() => updateQuestion!(question_)}
                style={{ backgroundColor: "dodgerblue", color: "white" }}
              />

              <input
                type="button"
                value="Disable"
                style={{ backgroundColor: "lightgrey", color: "white" }}
                defaultChecked={question.isActive}
                onClick={() => handleIsActiveChange(!question_.isActive)}
              />
            </div>

            <input
              type="button"
              value="Remove"
              onClick={() => deleteQuestion!(question_.id)}
              style={{ backgroundColor: "crimson", color: "white" }}
            />
          </div>
        </div>
      </Collapsible>
    </div>
  )
}
