import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { fetchCreateAnimal, fetchAnimals, fetchUpdateAnimal, fetchDeleteAnimal } from "../api/animals"

export interface AnimalContext {
  isLoading: boolean
  animals: Animal[]
  createAnimal: (animal: Animal) => Promise<void>
  updateAnimal: (animal: Animal) => Promise<void>
  deleteAnimal: (animalId: string) => Promise<void>
  loadNext: () => Promise<void>
}

const AnimalContext = createContext(null as any as AnimalContext)

export const useAnimals = () => useContext(AnimalContext)

export const AnimalProvider = ({ children }: PropsWithChildren) => {
  const [nextPage, setNextPage] = useState({ page: 1, count: 10 })
  const [animals, setAnimals] = useState<Animal[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loadNext().then(() => setIsLoading(false))
  }, [])

  const createAnimal = async (animal: Animal) => {
    await fetchCreateAnimal(animal)
    setAnimals([...animals, animal])
  }

  const updateAnimal = async (animal: Animal) => {
    await fetchUpdateAnimal(animal)
    setAnimals((prev) => [...prev.filter((a) => a.id !== animal.id), animal])
  }

  const deleteAnimal = async (animalId: string) => {
    await fetchDeleteAnimal(animalId)
    setAnimals((prev) => prev.filter((a) => a.id !== animalId))
  }

  const loadNext = async () => {
    const res = await fetchAnimals(nextPage.page, nextPage.count)

    if (res.results) {
      setNextPage((prev) => ({ ...prev, page: prev.page + 1 }))
      setAnimals((prev) => [...prev, ...res.results.map(entityToModel)])
    }
  }

  const entityToModel = (entity: AnimalEntity): Animal => {
    return {
      id: entity.id,
      name: entity.name,
      image: entity.image,
      specifications: entity.specifications ?? [],
      creationDate: entity.creation_date,
      lastModified: entity.last_modified,
      animalOrder: entity.animal_order,
    }
  }

  return (
    <AnimalContext.Provider value={{ isLoading, animals, createAnimal, updateAnimal, deleteAnimal, loadNext }}>
      {children}
    </AnimalContext.Provider>
  )
}
