import { PropsWithChildren } from "react"

interface CollapsibleProps extends PropsWithChildren {
  collapse: boolean
}

export const Collapsible = ({ collapse, children }: CollapsibleProps) => {
  return (
    <div className="collapsible-container" style={{ height: !collapse ? 0 : "fit-content" }}>
      {children}
    </div>
  )
}
