import { useState } from "react"
import { Collapsible } from "../Collapsible"
import { useAnimals } from "../../contexts/animal-provider"
import { Animal } from "./Animal"
import { AddAnimal } from "./AddAnimal"

export const AnimalsSection = () => {
  const [collapsed, setCollapsed] = useState(true)
  const { isLoading, animals, loadNext } = useAnimals()

  if (isLoading) {
    return null
  }

  return (
    <div className="section" id="animals">
      <h1>Animals</h1>
      <p>Manage the animals that will appear when submitting an interaction in the WildlifeNL mobile application.</p>

      <Collapsible collapse={collapsed}>
        <div className="subsections">
          <div className="subsection">
            <h4>Modify an animal</h4>

            <div className="item-list questions">
              {animals?.length ? (
                animals?.map((animal) => <Animal key={Math.random()} animal={animal} />)
              ) : (
                <div style={{ textAlign: "center", fontSize: 13 }}>No animals</div>
              )}
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: 16 }}>
              <input type="button" style={{ cursor: "pointer" }} value="Load next" onClick={loadNext} />
            </div>
          </div>

          <div className="subsection">
            <h4>Add an animal</h4>

            <AddAnimal />
          </div>
        </div>
      </Collapsible>

      <input
        type="button"
        className="toggle-btn"
        style={{ padding: "12px 0" }}
        value={collapsed ? "↑ Hide section" : "↓ Expand section"}
        onClick={() => setCollapsed((prev) => !prev)}
      />
    </div>
  )
}
