import axios from "axios"

const baseUrl = "https://api.wildlifedatabase.nl/api/controllers"

export const fetchQuestions = async (page: number = 1, count: number = 1) => {
  const response = await axios.get<PaginatedResults<QuestionEntity>>(
    baseUrl + `/questions.php?page=${page}&count=${count}`
  )
  return response.data
}

export const fetchInteractionTypes = async () => {
  const response = await axios.get<InteractionType[]>(baseUrl + "/interactions.php/types")
  return response.data
}

export const fetchUserTypes = async () => {
  const response = await axios.get<UserType[]>(baseUrl + "/users.php/types")
  return response.data
}

export const createQuestion = async (question: QuestionEntity) => {
  await axios.post(baseUrl + "/questions.php", question)
}

export const updateQuestion = async (question: QuestionEntity) => {
  await axios.put(baseUrl + "/questions.php?id=" + question.id, question)
}

export const deleteQuestion = async (questionId: string) => {
  await axios.delete(baseUrl + "/questions.php?id=" + questionId)
}
