import { useDownload } from "../hook/use-download"

export const DownloadSection = () => {
  const { setPassword, downloadData } = useDownload()

  return (
    <div className="section" id="download">
      <h1>Download</h1>
      <p>Download all interaction data submitted in the WildlifeNL mobile application as a CSV file.</p>

      <div className="download">
        <input type="button" className="download-btn" value="Download" onClick={downloadData} />
        <input
          type="text"
          className="password-input"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
    </div>
  )
}
