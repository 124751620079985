import { ChangeEvent, useRef, useState } from "react"
import { useQuestions } from "../../contexts/question-provider"

export const AddQuestion = () => {
  const { createQuestion, interactionTypes, userTypes, questionTypes } = useQuestions()
  const defaultQuestion: Partial<Question> = {
    interactionTypes: [],
    userTypes: [],
    specifications: [],
    questionType: questionTypes![0],
  }
  const [question, setQuestion] = useState(defaultQuestion)
  const optionRef = useRef<HTMLInputElement>(null)

  const needsPlaceholder = ["text", "dropdown", "multiselect"].includes(question!?.questionType!?.id)
  const needsOptions = ["radio_button", "dropdown", "multiselect"].includes(question!?.questionType!?.id)

  const handleCreate = async () => {
    if (!question.question) {
      alert("Please enter a value for question.")
      return
    }
    if (!question.interactionTypes?.length) {
      alert("Please select at least one interaction type this question applies to.")
      return
    }
    const question_ = question as Question
    await createQuestion!(question_)
    setQuestion(defaultQuestion)
    const input = document.getElementById("question") as HTMLInputElement
    input.value = ""
  }

  const handleAddOption = () => {
    const option = optionRef.current?.value

    if (option && !question.specifications!?.includes(option)) {
      const specifications = [...question.specifications!, option]
      setQuestion((prev) => ({
        ...prev!,
        specifications,
      }))
      optionRef.current.value = ""
    }
  }

  const handleRemoveOption = (option: string) => {
    const specifications = question.specifications!.filter((s) => s !== option)
    setQuestion((prev) => ({
      ...prev!,
      specifications,
    }))
  }

  const handleQuestionTypeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const label = event.target.options[event.target.selectedIndex].label
    const questionType = questionTypes?.filter((t) => t.label === label)[0]!
    setQuestion((prev) => ({ ...prev!, questionType }))
  }

  const handleOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const questionOrder = event.target.value as unknown as number
    setQuestion((prev) => ({ ...prev!, questionOrder }))
  }

  const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const question = event.target.value
    setQuestion((prev) => ({ ...prev!, question }))
  }

  const handlePlaceholderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const placeholder = event.target.value
    setQuestion((prev) => ({ ...prev!, placeholder }))
  }

  const handleIsOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isOptional = event.target.checked
    setQuestion((prev) => ({ ...prev!, isOptional }))
  }

  const handleAddInteractionType = (interactionType: InteractionType) => {
    const interactionTypes = [...question.interactionTypes!, interactionType]
    setQuestion((prev) => ({ ...prev!, interactionTypes }))
  }

  const handleAddUserType = (userType: UserType) => {
    const userTypes = [...question.userTypes!, userType]
    setQuestion((prev) => ({ ...prev!, userTypes }))
  }

  const handleRemoveInteractionType = (interactionType: InteractionType) => {
    const interactionTypes = question.interactionTypes!.filter((t) => t.id !== interactionType.id)
    setQuestion((prev) => ({ ...prev!, interactionTypes }))
  }

  const handleRemoveUserType = (userType: UserType) => {
    const userTypes = question.userTypes!.filter((t) => t.id !== userType.id)
    setQuestion((prev) => ({ ...prev!, userTypes }))
  }

  return (
    <div className="content form">
      <div className="item">
        <div>Question</div>
        <input
          type="text"
          id="question"
          placeholder="Enter a question"
          onChange={handleQuestionChange}
          style={{ width: 300 }}
        />
      </div>

      <div className="item">
        <div>Order</div>
        <input type="number" onChange={handleOrderChange} />
      </div>

      <div className="item">
        <div>Type</div>
        <select onChange={handleQuestionTypeSelect}>
          {questionTypes?.map((t) => (
            <option key={t.id} value={t.label}>
              {t.label}
            </option>
          ))}
        </select>
      </div>

      {needsPlaceholder && (
        <div className="item">
          <div>Placeholder</div>
          <input type="text" placeholder="Enter hint text" onChange={handlePlaceholderChange} style={{ width: 300 }} />
        </div>
      )}

      {needsOptions && (
        <div className="item">
          <div>Options</div>
          <div className="options">
            <div className="options-list">
              {question.specifications?.map((option) => (
                <div className="option" key={option}>
                  <input key={option} type="button" value="Remove" onClick={() => handleRemoveOption(option)} />
                  {option}
                </div>
              ))}
            </div>

            <div className="input-tray">
              <input type="button" value="Add" onClick={handleAddOption} />
              <input type="text" placeholder="Option 1" ref={optionRef} />
            </div>
          </div>
        </div>
      )}

      <div className="item">
        <div>Is optional</div>
        <input type="checkbox" onChange={handleIsOptionalChange} />
      </div>

      <div className="item">
        <div>Interaction types</div>
        <div className="radio-buttons">
          {interactionTypes?.map((x) => (
            <label key={x.id} className="radio-button-item">
              {x.label}
              <input
                type="checkbox"
                value={x.id}
                onChange={(e) => (e.target.checked ? handleAddInteractionType(x) : handleRemoveInteractionType(x))}
              />
            </label>
          ))}
        </div>
      </div>

      <div className="item">
        <div>Target users</div>
        <div className="radio-buttons">
          {userTypes?.map((x) => (
            <label key={x.id} className="radio-button-item">
              {x.label}
              <input
                type="checkbox"
                value={x.id}
                onChange={(e) => (e.target.checked ? handleAddUserType(x) : handleRemoveUserType(x))}
              />
            </label>
          ))}
        </div>
      </div>

      <div className="btns" style={{ margin: "16px 0" }}>
        <input
          type="button"
          value="Add question"
          style={{ backgroundColor: "green", color: "white" }}
          onClick={handleCreate}
        />
      </div>
    </div>
  )
}
