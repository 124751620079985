import "./App.css"
import { DownloadSection } from "./components/DownloadSection"
import { QuestionsSection } from "./components/questions/QuestionsSection"
import { AnimalsSection } from "./components/animals/AnimalsSection"

export const App = () => {
  return (
    <main>
      <DownloadSection />
      <QuestionsSection />
      <AnimalsSection />
    </main>
  )
}
