import { ChangeEvent, useRef, useState } from "react"
import { useAnimals } from "../../contexts/animal-provider"
import { imageToUrl } from "../../api/animals"

export const AddAnimal = () => {
  const optionRef = useRef<HTMLInputElement>(null)
  const animals = useAnimals()
  const [animal, setAnimal] = useState<Partial<Animal>>({
    name: "",
    specifications: [],
    animalOrder: animals.animals.length + 2,
  })

  const handleAnimalName = (event: ChangeEvent<HTMLInputElement>) => {
    setAnimal({ ...animal, name: event.target.value })
  }

  const handleCreate = async () => {
    if (animal.name === "") {
      alert("Please enter a value for animal name.")
    } else {
      await animals.createAnimal(animal as any).catch((err) => console.warn(err.message))
    }
  }

  const handleRemoveOption = (option: string) => {
    const specifications = animal.specifications?.filter((s) => s !== option)
    setAnimal((prev) => ({
      ...prev,
      specifications,
    }))
  }

  const handleAddOption = () => {
    const option = optionRef.current?.value

    if (option && !animal.specifications?.includes(option)) {
      const specifications = [...(animal.specifications ?? []), option]
      setAnimal((prev) => ({
        ...prev,
        specifications,
      }))
      optionRef.current.value = ""
    }
  }

  const handleAnimalImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.length ? event.target.files[0] : (null as any)
    const image = await imageToUrl(file)
    setAnimal((prev) => ({ ...prev, image }))
  }

  const handleOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const animalOrder = event.target.value as unknown as number
    setAnimal((prev) => ({ ...prev, animalOrder }))
  }

  return (
    <div className="content form">
      <div className="item">
        <div>Animal</div>
        <input type="text" id="animal" onChange={handleAnimalName} placeholder="Name" style={{ width: 300 }} />
      </div>

      <div className="item">
        <div>Options</div>
        <div className="options">
          <div className="options-list">
            {animal.specifications?.map((option) => (
              <div className="option" key={option}>
                <input type="button" value="Remove" onClick={() => handleRemoveOption(option)} />
                {option}
              </div>
            ))}
          </div>

          <div className="input-tray">
            <input type="button" value="Add" onClick={handleAddOption} />
            <input type="text" placeholder="Option 1" ref={optionRef} />
          </div>
        </div>
      </div>

      <div className="item">
        <div>Image</div>
        <img className="animal-image" src={animal.image} />
        <input type="file" id="image" onChange={handleAnimalImage} placeholder="Name" style={{ width: 300 }} />
      </div>

      <div className="item">
        <div>Order</div>
        <input value={animal.animalOrder} type="number" onChange={handleOrderChange} />
      </div>

      <div className="btns" style={{ margin: "16px 0" }}>
        <input
          type="button"
          value="Add Animal"
          style={{ backgroundColor: "green", color: "white" }}
          onClick={handleCreate}
        />
      </div>
    </div>
  )
}
