import axios from "axios"

const baseUrl = "https://api.wildlifedatabase.nl/api/controllers/download.php"

export const downloadData = async (password: string) => {
  const response = await axios.get(baseUrl + "?password=" + password, {
    responseType: "blob",
  })
  return response.data
}
