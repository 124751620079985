import { useState } from "react"
import { downloadData as downloadData_ } from "../api/download"

export const useDownload = () => {
  const [password, setPassword] = useState<string | undefined>(undefined)

  const downloadData = async () => {
    if (!password) {
      alert("Please enter a password")
      return
    }

    const data = await downloadData_(password)
    const blob = new Blob([data], {
      type: "application/octet-stream",
    })

    const link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    link.download = "interactions.csv"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    setPassword,
    downloadData,
  }
}
