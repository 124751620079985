import { ChangeEvent, useRef, useState } from "react"
import { Collapsible } from "../Collapsible"
import { useAnimals } from "../../contexts/animal-provider"
import { imageToUrl } from "../../api/animals"

interface AnimalProps {
  animal: Animal
}

export const Animal = ({ animal }: AnimalProps) => {
  const { updateAnimal, deleteAnimal } = useAnimals()
  const [animal_, setAnimal] = useState(animal)
  const [collapse, setCollapse] = useState(false)
  const optionRef = useRef<HTMLInputElement>(null)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    setAnimal((prev) => ({ ...prev, name }))
  }

  const handleRemoveOption = (option: string) => {
    const specifications = animal_.specifications.filter((s) => s !== option)
    setAnimal((prev) => ({
      ...prev,
      specifications,
    }))
  }

  const handleAddOption = () => {
    const option = optionRef.current?.value

    if (option && !animal_.specifications.includes(option)) {
      const specifications = [...animal_.specifications, option]
      setAnimal((prev) => ({
        ...prev,
        specifications,
      }))
      optionRef.current.value = ""
    }
  }

  const handleAnimalImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.length ? event.target.files[0] : (null as any)
    const image = await imageToUrl(file)
    setAnimal((prev) => ({ ...prev, image }))
  }

  const handleOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const animalOrder = event.target.value as unknown as number
    setAnimal((prev) => ({ ...prev, animalOrder }))
  }

  return (
    <div className="list-item">
      <div className="header">
        <div>{animal_.name}</div>
        <input
          type="button"
          className="toggle-btn"
          value={collapse ? "↑ Hide section" : "↓ Expand section"}
          onClick={() => setCollapse((prev) => !prev)}
        />
      </div>

      <Collapsible collapse={collapse}>
        <div className="content">
          <div className="item">
            <div>Name</div>
            <input type="text" defaultValue={animal_.name} onChange={handleNameChange} style={{ width: 300 }} />
          </div>

          <div className="item">
            <div>Options</div>
            <div className="options">
              <div className="options-list">
                {animal_.specifications.map((option) => (
                  <div className="option" key={option}>
                    <input type="button" value="Remove" onClick={() => handleRemoveOption(option)} />
                    {option}
                  </div>
                ))}
              </div>

              <div className="input-tray">
                <input type="button" value="Add" onClick={handleAddOption} />
                <input type="text" placeholder="Option 1" ref={optionRef} />
              </div>
            </div>
          </div>

          <div className="item">
            <div>Image</div>
            <img className="animal-image" src={animal_.image} />
            <input type="file" id="image" onChange={handleAnimalImage} placeholder="Name" style={{ width: 300 }} />
          </div>

          <div className="item">
            <div>Order</div>
            <input value={animal_.animalOrder} type="number" onChange={handleOrderChange} />
          </div>

          <div className="item">
            <div style={{ opacity: 0.5 }}>Creation date</div>
            <div style={{ opacity: 0.5 }}>
              {new Date(animal_.creationDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </div>
          </div>

          <div className="item">
            <div style={{ opacity: 0.5 }}>Modified date</div>
            <div style={{ opacity: 0.5 }}>
              {new Date(animal_.lastModified).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </div>
          </div>

          <div className="btns" style={{ margin: "16px 0" }}>
            <div className="btns">
              <input
                type="button"
                value="Save"
                onClick={() => updateAnimal(animal_)}
                style={{ backgroundColor: "dodgerblue", color: "white" }}
              />
            </div>

            <input
              type="button"
              value="Remove"
              onClick={() => deleteAnimal(animal_.id)}
              style={{ backgroundColor: "crimson", color: "white" }}
            />
          </div>
        </div>
      </Collapsible>
    </div>
  )
}
