import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import {
  createQuestion as createQuestion_,
  updateQuestion as updateQuestion_,
  deleteQuestion as deleteQuestion_,
  fetchInteractionTypes,
  fetchQuestions,
  fetchUserTypes,
} from "../api/questions"

interface QuestionProviderProps {
  isLoading: boolean
  questions: Question[]
  loadNext: () => Promise<void>
  userTypes: UserType[]
  interactionTypes: InteractionType[]
  questionTypes: QuestionType[]
  createQuestion: (question: Question) => Promise<void>
  updateQuestion: (question: Question) => Promise<void>
  deleteQuestion: (questionId: string) => Promise<void>
}

const QuestionContext = createContext<Partial<QuestionProviderProps>>({})

export const useQuestions = () => {
  const context = useContext(QuestionContext)
  if (!context) {
    throw new Error("useStorageContext must be used within a StorageProvider")
  }
  return context
}

export const QuestionProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true)
  const [questions, setQuestions] = useState<Question[]>([])
  const [userTypes, setUserTypes] = useState<UserType[]>([])
  const [interactionTypes, setInteractionTypes] = useState<InteractionType[]>([])
  const questionTypes: QuestionType[] = [
    {
      id: "text",
      label: "Text",
    },
    {
      id: "dropdown",
      label: "Dropdown",
    },
    {
      id: "star",
      label: "Star",
    },
    {
      id: "file",
      label: "File",
    },
    {
      id: "radio_button",
      label: "Radio Button",
    },
    {
      id: "multiselect",
      label: "Multiselect",
    },
  ]
  const [nextPage, setNextPage] = useState({ page: 1, count: 10 })

  useEffect(() => {
    setIsLoading(true)
    init().then(() => setIsLoading(false))
  }, [])

  const init = async () => {
    const interactionTypes = await fetchInteractionTypes()
    setInteractionTypes(interactionTypes)
    const userTypes = await fetchUserTypes()
    setUserTypes(userTypes)
    const response = await fetchQuestions(nextPage.page, nextPage.count)
    const questions = response.results.map((entity) => {
      return entityToModel(entity, interactionTypes, userTypes)
    })
    setQuestions(questions)
    setNextPage((prev) => ({ ...prev, page: prev.page + 1 }))
  }

  const entityToModel = (
    entity: QuestionEntity,
    interactionTypes: InteractionType[],
    userTypes: UserType[]
  ): Question => {
    const interactionTypes_ = entity.interaction_types.map((id) => {
      return interactionTypes.filter((t) => t.id === id)[0]
    })
    const userTypes_ = entity.user_types.map((id) => {
      return userTypes.filter((t) => t.id === id)[0]
    })
    const questionType_ = questionTypes.filter((t) => t.id === entity.type)[0]

    return {
      id: entity.id,
      questionType: questionType_,
      placeholder: entity.placeholder,
      question: entity.question,
      interactionTypes: interactionTypes_,
      userTypes: userTypes_,
      isActive: entity.is_active,
      isOptional: entity.is_optional,
      questionOrder: entity.question_order,
      creationDate: entity.creation_date,
      lastModified: entity.last_modified,
      specifications: entity.specifications,
    }
  }

  const modelToEntity = (model: Question): QuestionEntity => {
    return {
      id: model.id,
      interaction_types: model.interactionTypes.map((t) => t.id),
      user_types: model.userTypes.map((t) => t.id),
      type: model.questionType.id,
      placeholder: model.placeholder,
      is_active: model.isActive ?? true,
      is_optional: model.isOptional ?? false,
      question: model.question,
      question_order: model.questionOrder,
      specifications: model.specifications,
      animal_id: null,
      creation_date: model.creationDate,
      last_modified: model.lastModified,
      order: model.questionOrder,
    }
  }

  const createQuestion = async (question: Question) => {
    const entity = modelToEntity(question)
    await createQuestion_(entity)
    setQuestions((prev) => [...prev, question])
  }

  const updateQuestion = async (question: Question) => {
    const entity = modelToEntity(question)
    await updateQuestion_(entity)
    setQuestions((prev) => [...prev.filter((q) => q.id !== question.id), question])
  }

  const deleteQuestion = async (questionId: string) => {
    await deleteQuestion_(questionId)
    setQuestions((prev) => prev.filter((q) => q.id !== questionId))
  }

  const loadNext = async () => {
    const response = await fetchQuestions(nextPage.page, nextPage.count)
    if (response.results && (response.results as unknown as string) !== "No questions available") {
      const newQuestions = response.results?.map((question) => {
        return entityToModel(question, interactionTypes, userTypes)
      })
      setQuestions((prev) => [...prev, ...newQuestions])
      setNextPage((prev) => ({ ...prev, page: prev.page + 1 }))
    }
  }

  const context = {
    isLoading,
    questions,
    loadNext,
    userTypes,
    interactionTypes,
    questionTypes,
    createQuestion,
    updateQuestion,
    deleteQuestion,
  }

  return <QuestionContext.Provider value={context}>{children}</QuestionContext.Provider>
}
