import axios from "axios"

const baseUrl = "https://dev.wildlifedatabase.nl/api/controllers/animals.php"

export const fetchAnimals = async (page: number, count: number) => {
  const res = await axios.get<PaginatedResults<AnimalEntity>>(baseUrl + `?page=${page}&count=${count}`)
  return res.data
}

export const fetchCreateAnimal = async (params: {
  name: string
  specifications: any
  image: any
  animalOrder: number
}) => {
  await axios.post<AnimalEntity>(baseUrl, params)
}

export const fetchUpdateAnimal = async (params: {
  id: string
  name: string
  specifications: any
  animalOrder: number
}) => {
  await axios.put<AnimalEntity>(baseUrl, params)
}

export const fetchDeleteAnimal = async (animalId: string) => {
  await axios.delete<AnimalEntity>(baseUrl + `?id=${animalId}`)
}

export const imageToUrl = async (image: any): Promise<string> => {
  // uploading image to https://api.imgbb.com/
  const body = new FormData()
  body.set("key", "795da2c1f5780b930d83ae17bf965afe")
  body.append("image", image)
  const res = await axios.post("https://api.imgbb.com/1/upload", body)
  return res.data.data.display_url
}
