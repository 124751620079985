import ReactDOM from "react-dom/client"
import { App } from "./App"
import { QuestionProvider } from "./contexts/question-provider"
import { AnimalProvider } from "./contexts/animal-provider"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <QuestionProvider>
    <AnimalProvider>
      <App />
    </AnimalProvider>
  </QuestionProvider>
)
